<template>
  <v-icon v-if="value">{{$icon('i.Checked')}}</v-icon>
</template>

<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean
    },
    column: Column
  }
}
</script>
